import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import FormContact from "../components/FormContact";

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid contact mt-navbar marginTop" style={{ marginTop: "7%" }}>
      <div className="col-md-12 col-12 mt-0 ">
        <div className="col-md-12 col-12 mt-0">
          <h2 className=" mb-2 titleSlug text-center" style={{ paddingTop: "20px" }}>{("Contact")}</h2>
          <p className="mt-3 mb-3 infotext text-center">

            Vous souhaitez nous contacter? Vous pouvez soit nous envoyer un courriel à <span className="email"><a href="mailto:info@isoc.ht">info@isoc.ht</a></span> ou utiliser le numéro <a href="tel:+509 55 33 6888">+509 55 33 6888</a> .

          </p>
        </div>
      </div>
      {/** for message after send message to the server */}
      <div className="container mt-1">
        <div className="row">
          <FormContact />
        </div>
      </div>
      <Helmet>
        <title>ISOC Haïti | Contact </title>
        <meta name="description" content="L'association haïtienne Internet Society Chapitre Haïti (ISOC HT) ou ISOC Haïti est l'un des chapitres de l'Internet Society" />
      </Helmet>
    </div>
  );
}

