import React, { useEffect, useState } from "react";
import ControlledEditor from "../components/EditorComponent";
import { Card, Col, Container, Row } from "react-bootstrap";

import { setData } from "../services/dataService";

function Form() {
  const [title, setTitle] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [desc, setDesc] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    setData("price", {
      title,
      imgUrl,
      desc,
      content,
    });
    alert("Article has add !");
    setTitle("");
    setImgUrl("");
    setDesc("");
    setContent("");
  };

  return (
    <Container fluid className="marginTop">
      <Row>
        <Col md={6}>
          <h3>Enregistre votre article</h3>
          <Card style={{ padding: "1%" }}>
            <form>
              <div className="form-group my-3">
                <label htmlFor="exampleInputEmail1">Title</label>
                <input
                  type="text"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control "
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="exampleInputEmail1">Url de l'image</label>
                <input
                  type="text"
                  required
                  value={imgUrl}
                  onChange={(e) => setImgUrl(e.target.value)}
                  className="form-control "
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="exampleInputEmail1">Description</label>
                <textarea
                  rows={6}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  className="form-control "
                />
              </div>
            </form>
            <ControlledEditor setContent={setContent} content={content} />
            <button onClick={handleSubmit} className="btn btn-primary col-4 my-3">Envoyer</button>
          </Card>
        </Col>
        <Col md={6}>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
        </Col>
      </Row>
    </Container>
  );
}

export default Form;
