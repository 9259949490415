import React, { useEffect } from 'react'
import Section1 from '../pages-sections/purpose'

export default function Purpose() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='marginTop'>
    <Section1 />
    </div>
  )
}
