import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Nav.css"; // Create this CSS file for styling
import { Link } from "react-router-dom";

import Logo from "../../assets/logo.png";

const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "red", padding: "0px 10px" }}
    >



      <div className="row">

        <div className="col-md-12">


          <nav
            className={`navbar navbar-expand-lg navbar-light fixed-top scrolled 
            
          }`}
            style={{ padding: "10px 32px 0px 32px" }}
          >
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "#fff!important", backgroundColor: "#fff" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <Link to="/">
              <h3 className="logo-hide" style={{ padding: "5px", color: "#000" }}>
                ISOC HAITI
              </h3>{" "}
            </Link>

            <div
              className="row col-12 collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="row">

                <div className="col-md-3">

                  <Link className="navbar-brand" to="/">
                    <img
                      /**  src="https://cdn.isoc.fr/wp-content/uploads/2018/12/ISOC-FRANCE-Logo-Light-Core-RGB-1.png" */
                      src={Logo}
                      width="70%"
                      alt="Logo ISOC HAITI"
                      className="d-inline-block align-text-top"
                    />
                  </Link>
                </div>

                <div className="col-md-6 d-flex justify-content-center">
                  <ul class="navbar-nav ">
                    <li class="nav-item">
                      <Link
                        className={`nav-link active  ${scrolled ? "" : ""}`}
                        aria-current="page"
                        to="/about"
                      >
                        A propos
                      </Link>
                    </li>
                    <li class="nav-item dropdown">

                      <Link
                        className={`nav-link active dropdown-toggle  ${scrolled ? "" : ""
                          }`}
                        to="/blog"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Dans la presse
                      </Link>
                      <ul class="dropdown-menu">
                        <Link class="nav-link" to="/blog">
                          Blog
                        </Link>
                        <li>
                          <Link
                            className={`nav-link  ${scrolled ? "" : ""}`}
                            to="/presse"
                          >
                            Dans la presse
                          </Link>
                        </li>
                      </ul>



                      {/* <Link
                        className={`nav-link active dropdown-toggle  ${scrolled ? "" : ""}`}
                        to="/blog"
                      >
                        Dans la presse
                      </Link>
                      <Link
                        className={`nav-link  ${scrolled ? "" : ""}`}
                        to="/presse"
                      >
                        Dans la presse
                      </Link> */}
                    </li>
                    <li class="nav-item">
                      <Link
                        className={`nav-link active  ${scrolled ? "" : ""}`}
                        to="/projet"
                      >
                        Projets phares
                      </Link>
                    </li>
                    <li class="nav-item dropdown">
                      <Link
                        className={`nav-link active dropdown-toggle  ${scrolled ? "" : ""
                          }`}
                        to="/membre"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Devenir membre
                      </Link>
                      <ul class="dropdown-menu">
                        <Link class="nav-link" to="/membre">
                          Devenir membre
                        </Link>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://portal.internetsociety.org/622619/form/join"
                          >
                            Membre login
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                <div className="col-md-3 d-flex justify-content-end">
                  <ul class="navbar-nav ">
                    <li class="nav-item">
                      <Link
                        className={`nav-link active  ${scrolled ? "" : ""}`}
                        aria-current="page"
                        to="purpose"
                      >
                        opportunités
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        className={`nav-link active  ${scrolled ? "" : ""}`}
                        to="contact"
                      >
                        Contactez-nous
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div >
    </div >
  );
};

export default NavBar;
