import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

import AboutImg from "../../assets/about.jpg";

export default function Section1() {
  return (
    <Container fluid style={{ marginTop: "5%" }}>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center">A propos de l’Internet Society Haiti</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                A propos
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={6} style={{ marginTop: "4%" , marginBottom: "1%"}}>
            <img src={AboutImg} alt="logo" width="100%" height="100%" className="rounded" />
          </Col>
          <Col md={6}>
            <h3>A propos</h3>
            <p>
              L’Association{" "}
              <b> Internet Society Chapitre Haïti (ISOC Haïti) </b> est la
              branche haïtienne de l’Internet Society, un organisme œuvrant dans
              la gouvernance mondiale de l’Internet. Elle a pour mission de
              promouvoir sur le territoire haïtien et au bénéfice de tous, les
              conditions et les outils propices au développement d'une Société
              de l'Information et de la Connaissance respectueuse de la culture
              et des valeurs haïtiennes.
            </p>
            <p> Pour ce faire, l’Association se fixe pour objectifs de :</p>

            <ul>
              <li>
                Promouvoir l'accès et le service universel pour une plus grande
                égalité des chances dans l'utilisation des Technologies de
                l’Information et de la Communication (TIC)
              </li>
              <li>Favoriser le développement de l'économie numérique</li>
              <li>
                Promouvoir un cadre légal et un écosystème supportant le
                développement de la société de l'Information.
              </li>
              <li>
                Promouvoir l'e-gouvernement, l'administration électronique
              </li>
              <li>
                Promouvoir l'aménagement numérique du territoire et le
                développement des infrastructures.
              </li>
              <li>
                Favoriser l'éducation et la diffusion des connaissances
                nécessaires à une meilleure appropriation des TIC. Assurer une
                veille technologique garantissant la vulgarisation des nouvelles
                idées et la protection de la culture et des valeurs haïtiennes
                facilitant le renforcement de la cohésion sociale dans le
                développement de la Société de l'Information et de la
                Connaissance.
              </li>
            </ul>
          </Col>
          <Col md={12}>
            <p>
              Afin d'atteindre ses objectifs, l'Association organise ou
              participe à des activités nationales, régionales et globales se
              rapportant aux nouvelles technologies de l’information, établit
              des alliances stratégiques avec le gouvernement, des organisations
              nationales et internationales et des organismes poursuivant des
              objectifs similaires.
            </p>
            <p>
              La gestion de l’Association est confiée au Conseil
              d’Administration. Les membres du Conseil d’Administration sont
              élus par l’Assemblée Générale des membres.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
