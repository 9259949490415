import React from 'react'
import CarouselComponent from '../../components/CarouselComponent'

export default function Section1() {
  return (
    <div>
      <CarouselComponent />
    </div>
  )
}
