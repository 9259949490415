import React, { useState, useEffect } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
// import SubcribeFrom from "../components/subcribeFrom";
import { auth } from "../../firebase/firebaseConfig.js";
import { Link } from "react-router-dom";
const handleLogout = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
    // navigate("/lookup/community");
    window.location.reload();
    console.log("Signed out successfully");
  }).catch((error) => {
    // An error happened.
  });
};

function Footer() {
  const [isLogging, setisLogging] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        setisLogging(true);
        // ...
        // console.log("uid", uid)
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
      }
    });
  }, []);
  return (
    <div>
      <hr />
      <footer className="mt-5 no-print">
        <div className="container-fluid footer">
          <div className="row">
            <div className="col-md-12 col-12 ">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 col-6 product">
                    <ul className="ul">
                      <a href>
                        <li className="">
                          <Link to={'/prix'}>  {"Prix national et International  "}</Link>
                        </li>
                        <li><Link to={'/honneur'} >honneur et merite</Link></li>
                      </a>

                      <hr />
                      <li>
                        <span>
                          <i className="fa fa-envelope-o " aria-hidden="true" />
                        </span>{" "}
                        <a
                          href="mailto:info@isoc.ht"
                          className="text-line"
                        >
                          Envoyez-nous un email
                        </a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-phone " aria-hidden="true" />
                        </span>{" "}
                        <a
                          href="tel: +509 55 33 6888"
                          className="text-line"
                        >
                          +509 55 33 6888
                        </a>
                      </li>
                      <hr />
                    </ul>
                  </div>
                  <div className="col-md-3 col-6 developer">
                    <ul>
                      <li className=""> <a href={'/#partnaire'}>{"Partenaires"} </a> </li>
                      {isLogging ? (
                        <li onClick={handleLogout} style={{ cursor: 'pointer' }}>Se déconnecter</li>
                      ) : (
                        <li className=""><Link to="/login">Se connecter</Link> </li>
                      )}

                    </ul>
                  </div>
                  <div className="col-md-3 col-6 developer">
                    <ul>
                      <li className=""><a href="https://www.internetsociety.org/fr/learning/">Nos Cours</a> </li>
                      <li className=""><a href="https://bit.ly/3WCrhvy" target="_blank">Nos Documents</a> </li>
                    </ul>

                  </div>

                  <div className="col-md-3  col-6 resource">
                    <ul>
                      <li className=""> <Link to={'/politique'}> {"Politique de confidentialité"}</Link></li>

                      {/** email and number for icon */}
                      <li>
                        <span>
                          <i className="fa fa-envelope-o " aria-hidden="true" />
                        </span>{" "}
                        <a
                          href="mailto:info@isoc.ht"
                          className="text-line"
                        >
                          info@isoc.ht
                        </a>
                      </li>



                    </ul>
                  </div>
                </div>
              </div>
              <div className="container">
                <hr />

                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center  mt-5">
                    {/**   <SubcribeFrom /> */}
                    <div className="inline mt-5">
                      <a href="https://www.facebook.com/isochaiti">
                        {" "}
                        <i className="fa fa-facebook fa-2x m-3" />
                      </a>
                      <a href="https://www.youtube.com/@isochaiti">
                        {" "}
                        <i className="fa fa-youtube fa-2x m-3" />{" "}
                      </a>
                      <a href="https://www.linkedin.com/company/isochaiti/">
                        {" "}
                        <i className="fa fa-linkedin-square fa-2x m-3" />{" "}
                      </a>

                      <a href="https://www.instagram.com/isochaiti/">
                        {" "}
                        <i className="fa fa-instagram fa-2x m-3" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <hr className="m-0" />
      <div className="col-md-12">
        <div className="footer">
          <p>&copy; ISOC HAITI {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
