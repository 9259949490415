import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";

export default class EditorConvertToHTML extends Component {
  // Instance variable to track mounted state
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.setContent(
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    );
  };


  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor-custom"
          localization={{
            locale: "en",
          }}
        />
      </div>
    );
  }
}
