/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig.js";
import { Card } from "react-bootstrap";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const { user } = userCredential;
        window.location.reload();
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert("bad email or password try again");
      });
  };

  return (
    <Card className="p-5" style={{ margin: "10% 0%"   }}>
      <h1 style={{ textAlign: "center" }} > ISOC HAITI Login </h1>
      <form>
        <div className="form-group my-3">
          <label htmlFor="exampleInputEmail1">Email adresse</label>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control "
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <small id="emailHelp" className="form-text text-muted">
          Nous ne partagerons jamais votre e-mail avec qui que ce soit.
          </small>
        </div>
        <div className="form-group my-3">
          <label htmlFor="exampleInputPassword1">Mot de passe</label>
          <input
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            id="exampleInputPassword1"
          />
          <small id="emailHelp" className="form-text text-muted">
          8 caractères
          </small>
        </div>
        <button
          type="submit"
          onClick={onLogin}
          className="btn btn-primary btn-block"
        >
          Se connecter
        </button>
      </form>
    </Card>
  );
}
export default Login;
