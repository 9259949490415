import React from "react";
import { Col, Container, Row } from "react-bootstrap/esm";
import { Link } from "react-router-dom";

import MemberImg from "../../assets/membre.jpg"

export default function Section1() {
  return (
    <Container fluid style={{ marginTop: "5%" }}>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "#eee" }}
      >
        <Col md={4} style={{ margin: "5% 0" }}>
          <h3 text-align="center"> Devenir membre de l’Internet Society Haiti</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
              <Link to="/">Accueil</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Membre
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: "2%" }}>
          <Col md={6} style={{ marginTop: "2%" }}>
            <img
              src={MemberImg}
              alt="logo"
              width="100%"
              className="rounded"
            />
          </Col>
          <Col md={6} style={{ marginTop: "5%" }}>
            <h3> Devenir membre </h3>
            <p>
              Pour rejoindre notre chapitre local, la première étape est de
              devenir membre de l’organisation globale Internet Society{" "}
              <a href="https://portal.internetsociety.org/622619/form/join">
                ici
              </a>{" "}
              . Apres avoir inscrit, vous pouvez vous connecter pour postuler
              afin de devenir membre gratuitement du chapitre haïtien de
              l’Internet Society{" "}
              <a href="https://admin.internetsociety.org/622619/Entity/Grid?EntityTypeId=78a19f5c-d906-4fdb-a86c-909f90f1324a&ListingId=4f59d1a0-d3d5-4c9c-b040-3afa63821d61">
                ici
              </a>{" "}
              .
            </p>

            <p>
              Les Chapitres sont au cœur de notre travail, réunissant nos
              membres dans des groupes locaux et régionaux qui gèrent des
              programmes et des activités visant, entre autres, à informer les
              politiques et à éduquer le public sur les questions liées à
              Internet.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
