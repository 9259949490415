/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import ArticleItem from "../components/ArticleItem";
import db from "../services/dataService";

export default function Community() {
  const { articleId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getItem() {
      const docRef = doc(db, "price", articleId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          setData(docSnap.data());
        }
        console.log("Document does not exist");
      } catch (error) {
        console.log(error);
        return error;
      }
    }
    getItem();
  }, [articleId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(articleId);
  return (
    <div className="container-fuild marginTop">
      {data ? <ArticleItem data={data} /> : "loading"}
      <Helmet>
        <title>{` ISOC HAITI | ${data?.desc}`}</title>
        <meta name="description" content={data?.desc} />
      </Helmet>
    </div>
  );
}
