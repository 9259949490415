import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Section1 from "../pages-sections/home/section1";
import Section2 from "../pages-sections/presse/index";
import Section3 from "../pages-sections/about/section1"
import Contact from "./contact";
import Partnaire from "../pages-sections/partnaire";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="marginTop">
      <Section1 />
      <Section2 />
      <Partnaire />
      <Section3 />
      <Contact />
      <Helmet>
        <title>
          ISOC Haïti | Accueil{" "}
        </title>
        <meta
          name="description"
          content="L'association haïtienne Internet Society Chapitre Haïti (ISOC HT) ou ISOC Haïti est l'un des chapitres de l'Internet Society"
        />
      </Helmet>
    </div>
  );
}
