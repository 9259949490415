import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionPrix from "../pages-sections/prix/index";
import { Container, Row } from "react-bootstrap";

export default function Prix() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid className="marginTop">
      <Row>
        <SectionPrix />
      </Row>
      <Helmet>
        <title>ISOC Haïti | Prix national et International </title>
        <meta
          name="description"
          content="Depuis 2018, avec le badge Gigabit, l'Internet Society Chapitre Haïti est l'un des rares chapitres de l'Internet Society dans le monde qui est allé au-delà des exigences d'un chapitre de l'Internet Society avec le niveau le plus élevé"
        />
      </Helmet>
    </Container>
  );
}
