import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';

const items = [
  {
    id: 1,
    image: 'https://i1.wp.com/rezonodwes.com/wp-content/uploads/2022/02/1b7-scaled.jpg?fit=2560%2C1665&ssl=1',
    title: 'Internet Society Haiti',
    text: 'L’Association Internet Society Chapitre Haïti (ISOC Haïti) est la branche haïtienne de l’Internet Society',
  },
  {
    id: 2,
    image: "https://i2.wp.com/rezonodwes.com/wp-content/uploads/2022/04/isoc-haiti-tech-informatique.png?fit=1198%2C772&ssl=1",
    title: "ISOC Chapitre Haiti",
    text: "L'ISOC Haiti veut agir pour l'accès universel à Internet en Haïti par une formation pratique"
  }
];

const CarouselComponent = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const props = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.1)' },
    reset: true,
  });

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {items.map((item) => (
        <Carousel.Item key={item.id}>
          <animated.img
            className="d-block w-100 "
            src={item.image}
            alt={`Slide ${item.id}`}
            style={props}
          />
          <Carousel.Caption className="carousel-text position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="titre">{item.title}</h1>
            <h5 class="p">{item.text}</h5>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
